<template>
<section class="survival">
    <div class="page-header">
      <h3 class="page-title" >{{ $t('survival') }}</h3> 
    </div>
    <div>
      <span class="h3">{{title + ' '}}</span><span class="h5">(Total 7 Rounds)</span>
    </div>
    <div v-if="loading">
      <div class="pixel-loader"></div>
    </div>
    <div v-else>
      <div>
          <b-pagination :total-rows="7" v-model="currentRound" :per-page="1" danger class="rounded-flat"></b-pagination>
      </div>
      <event-detail v-if="eventId" :composite-event="eventId"/>
    </div>
  </section>
</template>

<script>
import eventDetail from './event-detail'

export default {
  components: {
    eventDetail
  },
  data() {
    return {
      loading: false,
      currentRound: 1,
      events: [],
      title: '',
      eventList: [],
      eventId: ''
    }
  },
  watch: {
    currentRound(newRound) {
      this.eventId = this.eventList[newRound < 1 ? 0 : newRound - 1];
    }
  },
  created() {
    this.$bobwinHub.$on('survivalEventsReceived', this.survivalEventsReceived);
    this.init();
  },
  beforeDestroy: function(){
    this.$bobwinHub.$off('survivalEventsReceived', this.survivalEventsReceived);
  },
  methods: {
    init() {
      this.loading = true;
      this.CallHub({task: 'GetSurvivalEvents', callback: 'survivalEventsReceived'});
    },
    survivalEventsReceived(response) {
      const groups = JSON.parse(response.data);
      for (const [key, value] of Object.entries(groups)) {
        this.title = key;
        this.eventList = value;
      }
      this.currentRound = this.getCurrentRound();
      this.loading = false;
    },
    getCurrentWeek() {
        const now = new Date();
        const startOfYear = new Date(now.getFullYear(), 0, 0);
        const diff = now - startOfYear;
        const oneWeekInMs = 1000 * 60 * 60 * 24 * 7;
        return Math.floor(diff / oneWeekInMs);
    },
    getCurrentRound() {
        const now = new Date();
        const currentDay = now.getDay();
        return currentDay === 0 ? 7 : currentDay
    },
    currentRoundClass(index) {
        return this.getCurrentRound() === index ? "currentRound" : "";
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-add-game-type {
    background: #24272a;
}
.col-form-label {
  font-size: 0.875rem;
    line-height: 1;
    vertical-align: top;
}
.option-input {
  height: calc(1.55rem + 2px);
  label {
    font-size: 0.8rem;  
  }
}
.game-list {
  padding: 0; 
}
.game-options {
  width: 100%;
  padding-right: 0;
}
.list-item {
  display: block;
}
.card {
  .card-title {
    margin-bottom: 0;
  }
}
.team-info {
  i {
    margin-bottom: 0.4rem;
  }
}
.start-time {
  text-align:center;
  i {
    font-size: 60%;
    margin-bottom: 10px;
  }
}
.ul-horizon {
  display: flex;
  justify-content: center;
  list-style-type: none;
}
.currentRound {
    font-size: 3rem;
}
</style>